import { useTranslations } from 'next-intl'
import { format, parseISO } from 'date-fns'
import { IMinerNode } from '@/types/miner.interface'
import {
  Box,
  Button,
  Flex,
  GridItem,
  Link,
  Image,
  SimpleGrid,
  Text,
  VStack,
} from '@chakra-ui/react'
import NextLink from 'next/link'
import { getHardwareLink } from '@/utils/getHardwareLink'
import { formatPrice } from '@/utils/formatPrice'
import { useAddToCart } from '@/hooks/useAddToCart'

interface IProps {
  node: IMinerNode
}

export const ProductCard = ({ node }: IProps) => {
  const t = useTranslations()
  const [alterCartMutation, { loading: alterCartLoading }] = useAddToCart({
    productId: node.productId,
    redirectToCheckout: true,
  })

  const getFeaturedMinerImage = (images: string[]) => {
    if (images.length) {
      return images[0]
    }
    // Fall back to miner image if there is no data
    return '/img/miner-hero-homepage.png'
  }

  return (
    <SimpleGrid
      key={node.minerModelId}
      columns={0}
      spacing={10}
      borderRadius="md"
    >
      <GridItem alignContent={{ base: 'start', sm: 'center' }}>
        <Flex
          alignContent={'center'}
          height={{ base: '250px', md: 'auto', xl: '600px' }}
          bg={'black'}
          position="relative"
          borderRadius="5px"
          textShadow="0px 4px 6px rgba(0, 0, 0, 0.33)"
        >
          <Box
            position="absolute"
            top={{ base: '20px', md: '40px', xl: '50px' }}
            left={{ base: '20px', md: '37px', xl: '47px' }}
            right={{ base: '25%' }}
            zIndex="2"
            flexDirection="column"
            textAlign={'left'}
          >
            <Text color={'gray.850'} fontSize={'14px'} lineHeight={'17px'}>
              {node.manufacturerName}
            </Text>
            <Text
              color={'white'}
              fontSize={{ base: '18px', md: '48px' }}
              lineHeight={{ base: '22px', md: '58px' }}
              width={{
                base: '100%',
                sm: '100%',
                lg: '75%',
                xl: '40%',
              }}
              fontWeight="700"
            >
              {node.minerModelName}
            </Text>
            <Text
              color={'white'}
              mt={{ base: '2px', md: '10px' }}
              fontSize={{ base: '12px', md: '22px' }}
              lineHeight={{ base: '100%', md: '32px' }}
            >
              {Math.floor(node.minerModelHashratePerTerahash)} TH/s |{' '}
              {node.minerModelPowerConsumptionWatts.toLocaleString()}w
            </Text>
          </Box>
          {/* ---- Bottom Text container ---- */}
          <VStack
            position="absolute"
            bottom={{ base: '5', md: '40px', xl: '52px' }}
            right={{ base: '10px', md: '30px', xl: '40px' }}
            zIndex="2"
            flexDirection="column"
            align={'flex-end'}
            spacing={{ base: '1px', md: '5px' }}
          >
            <Text
              color={'white'}
              fontSize={{ base: '12px', md: '12px' }}
              lineHeight={{ base: '18px', md: '18px' }}
              textTransform={'capitalize'}
            >
              {t('home.condition')}: {node.productCondition?.replace('_', ' ')}
            </Text>
            <Text
              color={'white'}
              fontSize={{ base: '12px', md: '12px' }}
              lineHeight={{ base: '18px', md: '18px' }}
            >
              {t('home.online-date')}:&nbsp;
              {node?.hostedTargetOnlineDate &&
                (format(parseISO(node?.hostedTargetOnlineDate), 'MM/yy') ??
                  'N/A')}
              {node?.countryName && ` @ ${node?.countryName}`}
            </Text>
            {/* <Text color={'white'} fontSize="sm">
              {t('hardware-list.current-monthly-rewards')}*:{' '}
              <Text as="span" fontWeight={'bold'}>
                {' '}
                $700
              </Text>
            </Text> */}
            <Text
              color={'white'}
              fontSize={{ base: '12px', md: '12px' }}
              lineHeight={{ base: '18px', md: '18px' }}
            >
              {t('hardware-list.monthly-hosting-charges')}:{' '}
              <Text as="span" fontWeight={'bold'}>
                {' '}
                {formatPrice(node.monthlyHostingCharges)}
              </Text>
            </Text>
            {/* <Text color={'white'} fontSize="sm">
              {t('hardware-list.current-monthly-net')}:{' '}
              <Text as="span" fontWeight={'bold'}>
                {' '}
                $600
              </Text>
            </Text> */}
            <Text
              color={'brand.600'}
              pt={{ base: '12px', md: '15px', xl: '28px' }}
              fontSize={{ base: '100%', md: '24px' }}
              lineHeight={'100%'}
              fontWeight={'bold'}
            >
              {formatPrice(node.productListingPrice)}
            </Text>
            {node.financingEligible && (
              <Text color={'gray.500'} fontSize="xs">
                {t('payment-plan.labels.payment-plan')}{' '}
                {t('payment-plan.labels.available')}
              </Text>
            )}
            <Flex direction={'column'}>
              <Button
                as="a"
                variant="brand"
                zIndex={5}
                w={{ base: '100px', md: '117px' }}
                mt={{ base: '10px', md: '18px', xl: '22px' }}
                height={{ base: '25px', md: '30px' }}
                fontSize={{ base: '11px', md: '12px' }}
                lineHeight={{ base: '100%', md: '18px' }}
                onClick={alterCartMutation}
                isLoading={alterCartLoading}
                loadingText={t('buttons.buy-now')}
                className='gtm-buy-now'
              >
                {t('buttons.buy-now')}
              </Button>
              <NextLink
                href={`${getHardwareLink(node.minerModelName)}?productId=${
                  node.productId
                }`}
                passHref
              >
                <Link
                  variant="colored"
                  fontSize={{ base: '12px', md: '14px' }}
                  lineHeight={{ base: '100%', md: '17px' }}
                  color="white"
                  textDecoration={'underline'}
                  cursor="pointer"
                  mt={{ base: '10px', md: '12px', xl: '15px' }}
                  textAlign={{
                    base: 'left',
                    sm: 'right',
                    md: 'left',
                    lg: 'right',
                  }}
                >
                  {t('buttons.more-details')}
                </Link>
              </NextLink>
            </Flex>
          </VStack>
          <Image
            rounded={'md'}
            bg={'black'}
            alt={'Featured ASIC miner'}
            src={getFeaturedMinerImage(node.productImagesPrioritized)}
            width={{ base: '80%', md: '100%', xl: '1200px' }}
            backgroundSize="contain"
            objectFit={'contain'}
            pointerEvents="none"
            objectPosition={{ base: 'bottom', md: 'initial' }}
          />
        </Flex>
      </GridItem>
    </SimpleGrid>
  )
}
