import { useEffect, useState, useCallback } from 'react'

const debounce = (
  limit: number | undefined,
  callback: { (): number; (args_0: unknown[]): void }
) => {
  let timeoutId: NodeJS.Timeout | undefined
  return (...args: unknown[]) => {
    if (timeoutId) {
      clearTimeout(timeoutId)
    }
    timeoutId = setTimeout(callback, limit, args)
  }
}

type Dimensions = {
  x: number
  y: number
  width: number
  height: number
  top: number
  right: number
  bottom: number
  left: number
}

type NodeProps = {
  getBoundingClientRect: () => Dimensions
  current: Element | null
}

type BoundingRect = {
  ref: (instance: HTMLDivElement | null) => void
  dimensions: Dimensions
  node: HTMLDivElement | null
}

function getDimensionObject(node: NodeProps): Dimensions {
  const rect = node.getBoundingClientRect()
  return {
    width: rect.width,
    height: rect.height,
    top: rect.top,
    left: rect.left,
    x: rect.x,
    y: rect.y,
    right: rect.right,
    bottom: rect.bottom,
  }
}

export function useBoundingRect(limit: number): BoundingRect {
  const [dimensions, setDimensions] = useState<Dimensions>({
    x: 0,
    y: 0,
    width: 0,
    height: 0,
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
  })
  const [node, setNode] = useState<HTMLDivElement | null>(null)

  const ref = useCallback((instance: HTMLDivElement | null) => {
    setNode(instance)
  }, [])

  useEffect(() => {
    if ('undefined' !== typeof window && node) {
      const measure = () =>
        window.requestAnimationFrame(() =>
          setDimensions(getDimensionObject(node))
        )

      measure()

      const listener = debounce(limit ? limit : 100, measure)

      window.addEventListener('resize', listener)
      window.addEventListener('scroll', listener)
      return () => {
        window.removeEventListener('resize', listener)
        window.removeEventListener('scroll', listener)
      }
    }
  }, [node, limit])

  return {
    ref,
    dimensions,
    node,
  }
}

export default useBoundingRect
