import React, { useCallback, useEffect, useState, useMemo, useRef } from 'react'

import {
  useMediaQuery,
  useTheme,
  VStack,
  Flex,
  Box,
  BoxProps,
  HStack,
  Image,
  Button,
} from '@chakra-ui/react'

import { ChevronRightIcon, ChevronLeftIcon } from '@chakra-ui/icons'
import { motion, useAnimation, useMotionValue } from 'framer-motion'
import { BsFillCircleFill, BsCircle } from 'react-icons/bs'
import useBoundingRect from '@/hooks/useBoundingRect'
import { RightArrowIcon } from '../icons/RightArrowIcon'

const MotionFlex = motion(Flex)

const transitionProps = {
  stiffness: 400,
  type: 'spring',
  damping: 60,
  mass: 3,
}

type HeightProps = {
  base?: number | string
  md?: number | string
  lg?: number | string
  xl?: number | string
  '2xl'?: number | string
}

interface IProps {
  gap: number
  children?: JSX.Element | JSX.Element[]
  containerWidth: number
  containerHeight: number | HeightProps
  showBottomSlider?: boolean
  showImagePreviewSlider?: boolean
  alwaysFullWidthContainer?: boolean
  showNavitationArrows?: boolean
  trackStyle?: BoxProps
}
type SliderProps = {
  children: React.ReactNode
  setTrackIsActive: React.Dispatch<React.SetStateAction<boolean>>
  initSliderWidth: (width: number) => void
  setActiveItem: React.Dispatch<React.SetStateAction<number>>
  activeItem: number
  constraint: number
  itemWidth: number
  positions: number[] | undefined
  gap: number
  showBottomSlider?: boolean
  showImagePreviewSlider?: boolean
  alwaysFullWidthContainer?: boolean
  showNavitationArrows?: boolean
}

type TrackProps = {
  setTrackIsActive: React.Dispatch<React.SetStateAction<boolean>>
  trackIsActive: boolean
  setActiveItem: React.Dispatch<React.SetStateAction<number>>
  activeItem: number
  constraint: number
  itemWidth: number
  positions: number[] | undefined
  gap: number
  children?: React.ReactNode
  multiplier?: number
  handleKeyDown?: (event: KeyboardEvent) => void
  trackStyle?: BoxProps
}

type handleDragProps = {
  event: MouseEvent | TouchEvent
  offset: { x: number; y: number }
  target: HTMLDivElement
  key: string
  preventDefault: () => void
  Event: MouseEvent | TouchEvent
  KeyboardEvent: KeyboardEvent
}

type handleDragEndProps = {
  event?: MouseEvent | TouchEvent
  direction: number
  offset: { x: number; y: number }
  velocity: { x: number; y: number }
  key: string
  onKeyUp?: (event: KeyboardEvent) => void
}

interface itemProps extends TrackProps {
  children?: React.ReactNode
  itemWidth: number
  gap: number
  index: number
  handleKeyDown?: (event: KeyboardEvent) => void
  event?: { key: string }
  containerHeight: number | HeightProps
  Event?: MouseEvent | TouchEvent
  multiplier?: number
}

const Carousel: React.FC<IProps> = ({
  children,
  gap,
  containerWidth,
  containerHeight,
  alwaysFullWidthContainer = false,
  showBottomSlider = false,
  showImagePreviewSlider = false,
  showNavitationArrows = false,
  trackStyle,
}) => {
  const [trackIsActive, setTrackIsActive] = useState(false)
  const [multiplier, setMultiplier] = useState(0.35)
  const [sliderWidth, setSliderWidth] = useState(0)
  const [activeItem, setActiveItem] = useState(0)
  const [constraint, setConstraint] = useState(0)
  const [itemWidth, setItemWidth] = useState(0)

  const initSliderWidth = useCallback(
    (width: number) => setSliderWidth(width),
    []
  )

  const positions = useMemo(
    () =>
      React.Children.map(children, (child, index) => {
        if (React.isValidElement(child)) {
          // Number(child?.key)
          return -Math.abs((itemWidth + gap) * index)
        }
      }),
    [children, itemWidth, gap]
  )
  const { breakpoints } = useTheme()

  const [isBetweenBaseAndMd] = useMediaQuery(
    `(min-width: ${breakpoints.base}) and (max-width: ${breakpoints.md})`
  )

  const [isBetweenMdAndXl] = useMediaQuery(
    `(min-width: ${breakpoints.md}) and (max-width: ${breakpoints.xl})`
  )

  const [isGreaterThanXL] = useMediaQuery(`(min-width: ${breakpoints.xl})`)

  useEffect(() => {
    // Adjust the width of the slider based on the width of the screen here:
    if (isBetweenBaseAndMd) {
      setItemWidth(sliderWidth - gap)
      setMultiplier(0.65)
      setConstraint(1)
    }
    if (isBetweenMdAndXl) {
      setItemWidth(sliderWidth / containerWidth - gap)
      setMultiplier(0.5)
      setConstraint(2)
    }
    if (isGreaterThanXL) {
      setItemWidth(sliderWidth / containerWidth - gap)
      setMultiplier(0.35)
      setConstraint(10)
    }
  }, [isBetweenBaseAndMd, isBetweenMdAndXl, isGreaterThanXL, sliderWidth, gap])

  const sliderProps = {
    setTrackIsActive,
    initSliderWidth,
    setActiveItem,
    activeItem,
    constraint,
    itemWidth,
    positions,
    gap,
  }

  const trackProps = {
    setTrackIsActive,
    trackIsActive,
    setActiveItem,
    sliderWidth,
    activeItem,
    constraint,
    multiplier,
    itemWidth,
    positions,
    gap,
    trackStyle,
  }

  const itemProps = {
    setTrackIsActive,
    trackIsActive,
    setActiveItem,
    activeItem,
    constraint,
    itemWidth,
    positions,
    gap,
  }

  return (
    <Slider
      {...sliderProps}
      showBottomSlider={showBottomSlider}
      showImagePreviewSlider={showImagePreviewSlider}
      alwaysFullWidthContainer={alwaysFullWidthContainer}
      showNavitationArrows={showNavitationArrows}
    >
      <Track {...trackProps}>
        {React.Children.map(children, (child, index) => {
          if (React.isValidElement(child)) {
            return (
              <Item
                {...itemProps}
                containerHeight={containerHeight}
                index={index}
                key={index}
              >
                {child}
              </Item>
            )
          }
        })}
      </Track>
    </Slider>
  )
}

const Slider = ({
  setTrackIsActive,
  initSliderWidth,
  setActiveItem,
  activeItem,
  constraint,
  positions,
  children,
  gap,
  showBottomSlider,
  showImagePreviewSlider,
  alwaysFullWidthContainer,
  showNavitationArrows,
}: SliderProps) => {
  const { ref, dimensions } = useBoundingRect(0)
  const { width } = dimensions
  useEffect(() => initSliderWidth(Math.round(width)), [width, initSliderWidth])

  const handleDecrementClick = () => {
    setTrackIsActive(true)
    if (positions) {
      !(activeItem === positions.length - positions.length) &&
        setActiveItem((prev) => prev - 1)
    }
  }

  const handleIncrementClick = () => {
    setTrackIsActive(true)
    if (positions) {
      !(activeItem === positions.length - constraint) &&
        setActiveItem((prev) => prev + 1)
    }
  }

  return (
    <>
      <Box
        ref={ref}
        w={{
          base: '100%',
          md: `${alwaysFullWidthContainer ? '100%' : `calc(100% + ${gap}px)`}`,
        }}
        alignContent={'center'}
        justifyContent={'center'}
        px={`${gap / 2}px`}
        position="relative"
        overflow="hidden"
      >
        {showNavitationArrows ? (
          <>
            <Button
              position={'absolute'}
              zIndex={1}
              top={'45%'}
              left="0"
              background={'black.400'}
              opacity={'40%'}
              _hover={{
                opacity: '60%',
              }}
              onClick={() => {
                handleDecrementClick()
              }}
              transform={'rotate(180deg)'}
              display={activeItem > 0 ? 'block' : 'none'}
              borderRadius={'unset'}
            >
              <RightArrowIcon
                color={'white'}
                width={{ base: '10px', lg: '16px' }}
              />
            </Button>
            <Button
              position={'absolute'}
              zIndex={1}
              top={'45%'}
              right="0"
              background={'black.400'}
              opacity={'40%'}
              _hover={{
                opacity: '60%',
              }}
              onClick={() => {
                handleIncrementClick()
              }}
              display={
                positions && activeItem < positions.length - constraint
                  ? 'block'
                  : 'none'
              }
              borderRadius={'unset'}
            >
              <RightArrowIcon
                color={'white'}
                width={{ base: '10px', lg: '16px' }}
              />
            </Button>
          </>
        ) : null}
        {children}
        {(showBottomSlider || showImagePreviewSlider) && (
          <Box mt={showBottomSlider && !showImagePreviewSlider ? '' : 3}>
            <HStack
              w="full"
              justify={'center'}
              spacing="10px"
              flexDirection={'row'}
              flexWrap={{ base: 'wrap', md: 'nowrap' }}
            >
              {showBottomSlider && !showImagePreviewSlider && (
                <ChevronLeftIcon
                  w={'6'}
                  cursor="pointer"
                  color={activeItem > 0 ? '#A0AEC0' : '#FFFFFF'}
                  onClick={() => {
                    handleDecrementClick()
                  }}
                />
              )}
              {React.Children.map(children, (child) => {
                if (React.isValidElement(child)) {
                  return (
                    <>
                      {child?.props?.children?.map(
                        // eslint-disable-next-line @typescript-eslint/no-explicit-any
                        (_: React.ReactNode | any, index: number) => {
                          return activeItem === index ? (
                            <React.Fragment key={index}>
                              {showBottomSlider ? (
                                <BsFillCircleFill color={'#FF6633'} size={14} />
                              ) : null}
                              {showImagePreviewSlider ? (
                                <Box width={{ base: '40px', md: '50%' }}>
                                  <Image
                                    src={_.props.children.props.src}
                                    maxW={'full'}
                                    rounded={'md'}
                                    alt={'Featured ASIC miner'}
                                    backgroundSize="contain"
                                    border={'solid'}
                                    borderColor={'brand.600'}
                                  />
                                </Box>
                              ) : null}
                            </React.Fragment>
                          ) : (
                            <React.Fragment key={index}>
                              {showBottomSlider ? (
                                <BsCircle
                                  color={'#A0AEC0'}
                                  size={14}
                                  cursor="pointer"
                                  onClick={() => {
                                    setActiveItem(index)
                                  }}
                                />
                              ) : null}
                              {showImagePreviewSlider ? (
                                <Box width={{ base: '40px', md: '50%' }}>
                                  <Image
                                    src={_.props.children.props.src}
                                    width={'full'}
                                    rounded={'md'}
                                    alt={'Featured ASIC miner'}
                                    backgroundSize="contain"
                                    cursor={'pointer'}
                                    onClick={() => {
                                      setActiveItem(index)
                                    }}
                                  />
                                </Box>
                              ) : null}
                            </React.Fragment>
                          )
                        }
                      )}
                    </>
                  )
                }
              })}
              {showBottomSlider && !showImagePreviewSlider && (
                <ChevronRightIcon
                  w={'6'}
                  cursor="pointer"
                  color={
                    positions && activeItem < positions.length - constraint
                      ? '#A0AEC0'
                      : '#FFFFFF'
                  }
                  onClick={() => {
                    handleIncrementClick()
                  }}
                />
              )}
            </HStack>
          </Box>
        )}
      </Box>
    </>
  )
}

const Track = ({
  setTrackIsActive,
  trackIsActive,
  setActiveItem,
  activeItem,
  constraint,
  multiplier = 1,
  itemWidth,
  positions,
  children,
  trackStyle = {},
}: TrackProps) => {
  const [dragStartPosition, setDragStartPosition] = useState(0)
  const controls = useAnimation()
  const x = useMotionValue(0)
  const node =
    useRef<HTMLDivElement>() as React.MutableRefObject<HTMLDivElement>
  const handleDragStart = () =>
    positions && setDragStartPosition(positions[activeItem])

  const handleDragEnd = (_: string, info: handleDragEndProps) => {
    const distance = info.offset.x
    const velocity = info.velocity.x * multiplier
    const direction = velocity < 0 || distance < 0 ? 1 : -1

    const extrapolatedPosition =
      dragStartPosition +
      (direction === 1
        ? Math.min(velocity, distance)
        : Math.max(velocity, distance))

    const closestPosition =
      (positions &&
        positions.reduce((prev, curr) => {
          return Math.abs(curr - extrapolatedPosition) <
            Math.abs(prev - extrapolatedPosition)
            ? curr
            : prev
        }, 0)) ||
      0

    if (
      positions &&
      !(closestPosition < positions[positions.length - constraint])
    ) {
      setActiveItem(positions.indexOf(closestPosition))
      controls.start({
        x: closestPosition,
        transition: {
          velocity: info.velocity.x,
          ...transitionProps,
        },
      })
    } else {
      if (positions) {
        setActiveItem(positions.length - constraint)
        controls.start({
          x: positions[positions.length - constraint],
          transition: {
            velocity: info.velocity.x,
            ...transitionProps,
          },
        })
      }
    }
  }

  const handleResize = useCallback(
    () =>
      controls.start({
        x: positions && positions[activeItem],
        transition: {
          ...transitionProps,
        },
      }),
    [activeItem, controls, positions]
  )

  const handleClick = useCallback(
    (event: handleDragProps) =>
      node.current?.contains(event.target)
        ? setTrackIsActive(true)
        : setTrackIsActive(false),
    [setTrackIsActive]
  )

  const handleKeyDown = useCallback(
    (event: handleDragProps) => {
      if (trackIsActive && positions) {
        if (activeItem < positions.length - constraint) {
          if (event.key === 'ArrowRight' || event.key === 'ArrowUp') {
            event.preventDefault()
            setActiveItem((prev) => prev + 1)
          }
        }
        if (activeItem > positions.length - positions.length) {
          if (event.key === 'ArrowLeft' || event.key === 'ArrowDown') {
            event.preventDefault()
            setActiveItem((prev) => prev - 1)
          }
        }
      }
    },
    [
      trackIsActive,
      setActiveItem,
      activeItem,
      constraint,
      positions && positions.length,
    ]
  )

  useEffect(() => {
    handleResize()
  }, [handleClick, handleResize, handleKeyDown, positions])

  return (
    <>
      {itemWidth && (
        // TODO (AR): TS Fix
        <VStack ref={node} spacing={5} alignItems="stretch" {...trackStyle}>
          <MotionFlex
            dragConstraints={node}
            onDragStart={handleDragStart}
            onDragEnd={handleDragEnd}
            animate={controls}
            style={{ x }}
            drag="x"
            _active={{ cursor: 'grabbing' }}
            minWidth="min-content"
            flexWrap="nowrap"
            cursor="grab"
          >
            {children}
          </MotionFlex>
        </VStack>
      )}
    </>
  )
}

const Item = ({
  setTrackIsActive,
  itemWidth,
  positions,
  children,
  index,
  gap,
  containerHeight,
}: itemProps) => {
  const [userDidTab, setUserDidTab] = useState(false)

  const handleFocus = () => setTrackIsActive(true)

  const handleBlur = () => {
    positions &&
      userDidTab &&
      index + 1 === positions.length &&
      setTrackIsActive(false)
    setUserDidTab(false)
  }

  return (
    <Flex
      onFocus={handleFocus}
      onBlur={handleBlur}
      w={`${itemWidth}px`}
      _notLast={{
        mr: `${gap}px`,
      }}
      py="4px"
      height={containerHeight}
      justify={'center'}
    >
      {children}
    </Flex>
  )
}

export default Carousel
